var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"entryRecord"},[_c('van-nav-bar',{attrs:{"title":"入职记录","left-text":"","left-arrow":""},on:{"click-left":_vm.onClickLeft,"click-right":_vm.onClickRight}}),_c('van-tabs',{attrs:{"color":"#2B8DF0"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('van-tab',{attrs:{"title":"最近入职"}},[(_vm.entryList.length > 0)?_c('ul',_vm._l((_vm.entryList),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"zp"},[_c('img',{attrs:{"src":require("@/assets/img/interview_pic1.png"),"alt":""}})]),_c('div',{staticClass:"row1"},[_c('span',[_vm._v(_vm._s(item.name))]),_c('svg-icon',{attrs:{"icon-class":"induction_link"}}),_c('span',[_vm._v(_vm._s(item.gw))])],1),_c('div',{staticClass:"row3"},[_c('span',[_vm._v("部门：")]),_c('span',[_vm._v("仓储部")])]),_c('div',{staticClass:"row4"},[_c('div',{staticClass:"row4_left"},[_c('span',[_vm._v("入职日期：")]),_c('span',[_vm._v(_vm._s(item.dgsj))])]),_c('span',[_vm._v("已入职")])])])}),0):_vm._e()]),_c('van-tab',{attrs:{"title":"放弃入职"}},[_c('ul',_vm._l((_vm.resumeList),function(item,index){return _c('li',{key:index},[_c('div',{staticClass:"reListUp"},[_c('div',{staticClass:"zp"},[_c('img',{attrs:{"src":require("@/assets/img/interview_pic1.png"),"alt":""}})]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"age"},[_c('span',[_vm._v(_vm._s(item.jy))]),_c('span',[_vm._v(_vm._s(item.xl))]),_c('span',[_vm._v(_vm._s(item.age))])])]),_c('div',{staticClass:"reListDown"},[_c('van-row',{attrs:{"type":"flex","justify":"space-between"}},[_c('van-col',{attrs:{"span":"12"}},[_vm._v(_vm._s(item.gw))]),_c('van-col',{attrs:{"span":"12"}},[_c('span',{style:([
                    { color: item.zt == '初筛淘汰' ? '#999' : '#2B8DF0' },
                    {
                      border:
                        item.zt == '初筛淘汰'
                          ? '1px solid #999'
                          : '1px solid #2B8DF0',
                    } ])},[_vm._v(_vm._s(item.zt))])])],1)],1)])}),0)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }