<template>
  <!-- 入职记录 -->
  <div class="entryRecord">
    <van-nav-bar
      title="入职记录"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <van-tabs v-model="active" color="#2B8DF0">
      <van-tab title="最近入职">
        <ul v-if="entryList.length > 0">
          <li v-for="(item, index) in entryList" :key="index">
            <div class="zp">
              <img src="@/assets/img/interview_pic1.png" alt="" />
            </div>
            <div class="row1">
              <span>{{ item.name }}</span>
              <svg-icon icon-class="induction_link"></svg-icon>
              <span>{{ item.gw }}</span>
            </div>
            <!-- <div class="row2">
              <span>{{ item.tel }}</span>
              <svg-icon icon-class="interview_tel"></svg-icon>
              <svg-icon icon-class="interview_wx"></svg-icon>
            </div> -->
            <div class="row3">
              <span>部门：</span>
              <span>仓储部</span>
            </div>
            <div class="row4">
              <div class="row4_left">
                <span>入职日期：</span>
                <span>{{ item.dgsj }}</span>
              </div>
              <span>已入职</span>
            </div>
          </li>
        </ul>
      </van-tab>
      <van-tab title="放弃入职">
         <ul>
          <li v-for="(item, index) in resumeList" :key="index">
            <div class="reListUp">
              <div class="zp">
                <img src="@/assets/img/interview_pic1.png" alt="" />
              </div>
              <div class="name">{{ item.name }}</div>
              <div class="age">
                <span>{{ item.jy }}</span>
                <span>{{ item.xl }}</span>
                <span>{{ item.age }}</span>
              </div>
            </div>
            <div class="reListDown">
              <van-row type="flex" justify="space-between">
                <van-col span="12">{{ item.gw }}</van-col>
                <van-col span="12"
                  ><span
                    :style="[
                      { color: item.zt == '初筛淘汰' ? '#999' : '#2B8DF0' },
                      {
                        border:
                          item.zt == '初筛淘汰'
                            ? '1px solid #999'
                            : '1px solid #2B8DF0',
                      },
                    ]"
                    >{{ item.zt }}</span
                  ></van-col
                >
              </van-row>
            </div>
          </li>
        </ul>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: "first",
      entryList: [
        {
          name: "周先生",
          gw: "原材料仓管员",
          tel: "13595486227",
          jy: "3年经验",
          xl: "大专",
          dgsj: "2021-04-18 08:00",
        },
        {
          name: "陈先生",
          gw: "原材料仓管员",
          tel: "13595486227",
          jy: "3年经验",
          xl: "大专",
          dgsj: "2021-04-18 08:00",
        },
        {
          name: "黄先生",
          gw: "原材料仓管员",
          tel: "13595486227",
          jy: "3年经验",
          xl: "大专",
          dgsj: "2021-04-18 08:00",
        },
        {
          name: "刘先生",
          gw: "原材料仓管员",
          tel: "13595486227",
          jy: "3年经验",
          xl: "大专",
          dgsj: "2021-04-18 08:00",
        },
        {
          name: "郑先生",
          gw: "原材料仓管员",
          tel: "13595486227",
          jy: "3年经验",
          xl: "大专",
          dgsj: "2021-04-18 08:00",
        },
        {
          name: "蒋先生",
          gw: "原材料仓管员",
          tel: "13595486227",
          jy: "3年经验",
          xl: "大专",
          dgsj: "2021-04-18 08:00",
        },
        {
          name: "彭先生",
          gw: "原材料仓管员",
          tel: "13595486227",
          jy: "3年经验",
          xl: "大专",
          dgsj: "2021-04-18 08:00",
        },
      ],
         resumeList: [
        {
          name: "周先生",
          jy: "1年经验",
          xl: "本科",
          age: "23岁",
          gw: "人事专员",
          zt: "初筛淘汰",
        },
        {
          name: "陈先生",
          jy: "1年经验",
          xl: "本科",
          age: "23岁",
          gw: "人事专员",
          zt: "初筛淘汰",
        },
        {
          name: "黄先生",
          jy: "1年经验",
          xl: "本科",
          age: "23岁",
          gw: "人事专员",
          zt: "初筛淘汰",
        },
        {
          name: "李先生",
          jy: "1年经验",
          xl: "本科",
          age: "23岁",
          gw: "人事专员",
          zt: "初筛淘汰",
        },
        {
          name: "聂先生",
          jy: "1年经验",
          xl: "本科",
          age: "23岁",
          gw: "人事专员",
          zt: "初筛淘汰",
        },
        {
          name: "卫先生",
          jy: "1年经验",
          xl: "本科",
          age: "23岁",
          gw: "人事专员",
          zt: "初筛淘汰",
        },
      ],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push("/applyEntry");
    },
    onClickRight() {},
  },
};
</script>

<style lang="less" scoped>
.entryRecord {
  height: 100vh;
  overflow: hidden;
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    /deep/ .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-size: 32px;
      }
    }
  }
  /deep/.van-tabs {
    height: calc(100% - 92px);
    .van-tabs__wrap {
      margin-bottom: 24px;
      .van-tabs__nav {
      }
    }
    .van-tabs__content {
   height: calc(100% - 108px);
   .van-tab__pane:first-child{
  height: 100%;
     ul {
       height: 100%;
       overflow: auto;
       li {
         position: relative;
         height: 258px;
         padding: 0 30px;
         background: #fff;
         padding-top: 38px;
         margin-bottom: 24px;
         box-sizing: border-box;
         .zp {
           position: absolute;
           right: 46px;
           top: 38px;
           width: 106px;
           height: 106px;
           img {
             width: 100%;
             height: 100%;
           }
         }
         .row1 {
           font-size: 40px;
           color: #000;
           display: flex;
           align-items: center;
           margin-bottom: 26px;
           line-height: 40px;
           .svg-icon {
             // width: 24px;
             // height: 40px;
             font-size: 30px;
             line-height: 40px;
             margin: 0 26px;
           }
         }
         .row2 {
           font-size: 32px;
           color: #2b8df0;
           margin-bottom: 18px;
           line-height: 32px;
           span {
             margin-right: 10px;
           }
           .svg-icon {
             font-size: 36px;
           }
           .svg-icon:nth-child(2) {
             margin: 0 18px;
           }
         }
         .row3 {
           font-size: 28px;
           color: #666;
           margin-bottom: 58px;
           line-height: 28px;
          
         }
         .row4 {
           display: flex;
           justify-content: space-between;
           align-items: center;
           color: #2b8df0;
           font-size: 28px;
           line-height: 28px;
           > span {
             display: inline-block;
             box-sizing: border-box;
             width: 138px;
             height: 48px;
             font-size: 28px;
             line-height: 48px;
             text-align: center;
             border: 1px solid #2b8df0;
           }
         }
       }
     }
   }
   .van-tab__pane:last-child{
     height: 100%;
      ul {
        height: 100%;
        overflow: auto;
          li {
            position: relative;
            height: 266px;
            padding: 0 30px;
            padding-bottom: 0;
            padding-top: 30px;
            background: #fff;
            box-sizing: border-box;
            margin-bottom: 24px;
            .reListUp {
              border-bottom: 1px solid #ccc;
              // margin-bottom: 20px;
              height: calc(100% - 100px);
              .zp {
                position: absolute;
                top: 28px;
                right: 54px;
                width: 106px;
                height: 106px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              .name {
                font-size: 40px;
                line-height: 1;
                font-family: Source Han Sans CN;
                color: #000;
                margin-bottom: 20px;
              }
              .age {
                font-size: 28px;
                font-family: Source Han Sans CN;
                color: #666;
                padding-bottom: 40px;
                span:first-child {
                  border-right: 1px solid #999;
                  padding-right: 14px;
                }
                span:nth-child(2) {
                  margin: 0 14px;
                }
              }
            }
            .reListDown {
              height: 100px;
              line-height: 100px;
              .van-row {
                .van-col:first-child {
                  display: flex;
                  align-items: center;
                  font-size: 36px;
                  font-family: Source Han Sans CN;
                  color: #666;
                }
                .van-col:last-child {
                  text-align: right;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  span {
                    display: inline-block;
                    width: 156px;
                    height: 54px;
                    box-sizing: border-box;
                    font-size: 32px;
                    font-family: Source Han Sans CN;
                    font-weight: 500;
                    border: 1px solid #999;
                    color: #999;
                    line-height: 56px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
   }
    }
  }
}
</style>
